var render = function render(){
  var _vm$order$quote, _vm$order$quote$dispa, _vm$order$quote3, _vm$order$quote3$disp, _vm$$appConfig$brand$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-order"
  }, [_vm.order ? _c('CancelOrder', {
    ref: "cancelOrderRef",
    attrs: {
      "order": _vm.order
    },
    on: {
      "cancelOrder": _vm.cancelOrder
    }
  }) : _vm._e(), _c('div', {
    staticClass: "page-order-screen"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('Order')) + " "), _vm.order ? [_vm._v("- " + _vm._s(_vm.order.invoiceNumber))] : _vm._e()], 2), _vm.order ? _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('SupportEmail', {
    staticClass: "mr-2",
    attrs: {
      "hidden-pre-subject": `[ORDER-${_vm.order.invoiceNumber}]`,
      "pre-subject": `${_vm.$t('Order')} ${_vm.order.invoiceNumber}`
    }
  }), _c('a', {
    ref: "invoicePDFRef",
    staticClass: "d-none",
    attrs: {
      "download": `Invoice_Beelse_${_vm.order.invoiceNumber}`
    }
  }), _c('v-tooltip', {
    attrs: {
      "top": "",
      "disabled": !!(_vm.order.public && _vm.order.invoiceFile) || !_vm.order.public && ((_vm$order$quote = _vm.order.quote) === null || _vm$order$quote === void 0 ? void 0 : (_vm$order$quote$dispa = _vm$order$quote.dispatch[0]) === null || _vm$order$quote$dispa === void 0 ? void 0 : _vm$order$quote$dispa.invoiceFile)
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _vm$order$quote2, _vm$order$quote2$disp;
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('v-btn', {
          staticClass: "text-none white--text",
          attrs: {
            "color": "orange",
            "loading": _vm.loadingPdf,
            "ripple": false,
            "disabled": _vm.order.public && !_vm.order.invoiceFile || !_vm.order.public && !((_vm$order$quote2 = _vm.order.quote) !== null && _vm$order$quote2 !== void 0 && (_vm$order$quote2$disp = _vm$order$quote2.dispatch[0]) !== null && _vm$order$quote2$disp !== void 0 && _vm$order$quote2$disp.invoiceFile)
          },
          on: {
            "click": _vm.getOrderPDF
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-file-pdf ")]), _vm._v(" " + _vm._s(_vm.$t('exportInvoice')) + " ")], 1)], 1)];
      }
    }], null, false, 2162984976)
  }, [!_vm.order.billing ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('NoBillingForThisOrder')) + " ")]) : !((_vm$order$quote3 = _vm.order.quote) !== null && _vm$order$quote3 !== void 0 && (_vm$order$quote3$disp = _vm$order$quote3.dispatch[0]) !== null && _vm$order$quote3$disp !== void 0 && _vm$order$quote3$disp.invoiceFile) ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('InvoiceNotReady')) + " ")]) : _vm._e()]), _vm.order && _vm.order.status === 'NEW' && _vm.order.paymentStatus !== 'PAID' ? _c('v-btn', {
    staticClass: "ml-2 text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.cancelOrderRef.openDialog();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('CancelOrder')) + " ")], 1) : _vm._e()], 1) : _vm._e()], 1), _vm.order && _vm.order.status === 'CANCELED' ? _c('v-divider', {
    staticClass: "my-4"
  }) : _vm._e(), _vm.order && _vm.order.status === 'CANCELED' ? _c('v-alert', {
    staticClass: "text-center",
    attrs: {
      "outlined": "",
      "prominent": "",
      "text": "",
      "type": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('OrderHasBeenCanceled')) + " ")]) : _vm._e(), _c('v-divider', {
    staticClass: "mt-5 mb-5"
  }), _vm.paymentIntent ? _c('v-dialog', {
    attrs: {
      "persistent": !_vm.paymentSuccess,
      "max-width": "750px",
      "content-class": "page-order"
    },
    model: {
      value: _vm.modalPayment,
      callback: function ($$v) {
        _vm.modalPayment = $$v;
      },
      expression: "modalPayment"
    }
  }, [_c('v-card', {
    attrs: {
      "min-height": "200px"
    }
  }, [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('PayByCard')) + " ")]), _c('v-btn', {
    staticStyle: {
      "position": "absolute",
      "right": "15px"
    },
    attrs: {
      "icon": "",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.modalPayment = false;
      }
    }
  }, [_c('v-icon', [_vm._v("fas fa-times")])], 1)], 1), _c('v-divider', {
    staticClass: "mb-2"
  }), _vm.paymentSuccess ? _c('v-card-text', [_vm.paymentSuccess ? _c('v-alert', {
    staticClass: "mb-0 text-center",
    attrs: {
      "type": "success",
      "text": "",
      "prominent": ""
    }
  }, [_c('p', {
    staticClass: "text-h5"
  }, [_vm._v(" " + _vm._s(_vm.$t('OrderPaid')) + " ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('ThankYouForYourConfidence')) + " ")])]) : _vm._e()], 1) : _vm._e(), !_vm.paymentSuccess ? _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('PaymentChoice')))]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "disabled": _vm.paymentSuccess
    },
    on: {
      "change": _vm.changePaymentMethod
    },
    model: {
      value: _vm.choicePayment,
      callback: function ($$v) {
        _vm.choicePayment = $$v;
      },
      expression: "choicePayment"
    }
  }, [_c('v-radio', {
    attrs: {
      "disabled": _vm.savedPaymentMethods.length === 0,
      "label": _vm.$t('ExistingCard'),
      "value": 0
    }
  }), _c('v-radio', {
    staticClass: "mb-3",
    attrs: {
      "label": _vm.$t('NewCard'),
      "value": 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_vm.newPaymentMethod ? _c('v-checkbox', {
    attrs: {
      "disabled": _vm.paymentSuccess,
      "label": _vm.$t('SaveCard')
    },
    model: {
      value: _vm.checkboxSaveCard,
      callback: function ($$v) {
        _vm.checkboxSaveCard = $$v;
      },
      expression: "checkboxSaveCard"
    }
  }) : _vm._e(), !_vm.newPaymentMethod ? _c('v-select', {
    attrs: {
      "disabled": _vm.paymentSuccess,
      "items": _vm.savedPaymentMethods,
      "item-value": "cardInfo"
    },
    on: {
      "change": _vm.resetAlert
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (data) {
        return [data.item.cardInfo.card.brand === 'visa' ? _c('v-icon', {
          staticClass: "mr-3"
        }, [_vm._v(" fab fa-cc-visa ")]) : data.item.cardInfo.card.brand === 'mastercard' ? _c('v-icon', {
          staticClass: "mr-3"
        }, [_vm._v(" fab fa-cc-mastercard ")]) : data.item.cardInfo.card.brand === 'amex' ? _c('v-icon', {
          staticClass: "mr-3"
        }, [_vm._v(" fab fa-cc-amex ")]) : _c('v-icon', {
          staticClass: "mr-3"
        }, [_vm._v(" far fa-credit-card ")]), _vm._v(" " + _vm._s(data.item.cardText) + " ")];
      }
    }, {
      key: "item",
      fn: function (data) {
        return [data.item.cardInfo.card.brand === 'visa' ? _c('v-icon', {
          staticClass: "mr-3"
        }, [_vm._v(" fab fa-cc-visa ")]) : data.item.cardInfo.card.brand === 'mastercard' ? _c('v-icon', {
          staticClass: "mr-3"
        }, [_vm._v(" fab fa-cc-mastercard ")]) : data.item.cardInfo.card.brand === 'amex' ? _c('v-icon', {
          staticClass: "mr-3"
        }, [_vm._v(" fab fa-cc-amex ")]) : _c('v-icon', {
          staticClass: "mr-3"
        }, [_vm._v(" far fa-credit-card ")]), _vm._v(" " + _vm._s(data.item.cardText) + " ")];
      }
    }], null, false, 4021747836),
    model: {
      value: _vm.cardSelection,
      callback: function ($$v) {
        _vm.cardSelection = $$v;
      },
      expression: "cardSelection"
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e(), !_vm.paymentSuccess ? _c('v-divider', {
    staticClass: "mb-2"
  }) : _vm._e(), !_vm.paymentSuccess ? _c('v-card-text', [_vm.newPaymentMethod ? _c('StripePayment', {
    attrs: {
      "api-key": _vm.$stripeConfig.pk,
      "client-secret": _vm.paymentIntent.client_secret,
      "save-payment": _vm.checkboxSaveCard
    },
    on: {
      "paid": function ($event) {
        _vm.getOrder();
        _vm.paymentSuccess = true;
      }
    }
  }) : _c('v-btn', {
    staticClass: "mb-3 mt-6 text-none white--text",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "ripple": false,
      "block": "",
      "color": "orange"
    },
    on: {
      "click": _vm.payOrder
    }
  }, [_c('v-spacer'), _vm._v(" " + _vm._s(_vm.$t("Pay")) + " "), _c('span', {
    staticClass: "ml-3"
  }, [_vm._v(" " + _vm._s(_vm.$n(_vm.paymentIntent.amount / 100, "currency")) + " ")]), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-lock ")])], 1), _vm.errorMessage ? _c('v-alert', {
    staticClass: "mt-5 mb-5",
    attrs: {
      "type": "error",
      "outlined": "",
      "dismissible": ""
    },
    on: {
      "click": _vm.resetAlert
    },
    model: {
      value: _vm.showAlert,
      callback: function ($$v) {
        _vm.showAlert = $$v;
      },
      expression: "showAlert"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e()], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "flex-wrap-reverse justify-space-around"
  }, [_c('v-col', [_c('v-card', [_c('v-card-text', {
    staticClass: "black--text"
  }, [_vm.order ? _c('OrderStepperV2', {
    attrs: {
      "order": _vm.order,
      "display-delivery-tracking": true
    }
  }) : _vm._e(), _c('v-divider', {
    staticClass: "my-5"
  }), _vm.order ? [_c('div', {
    attrs: {
      "id": "quoteInfo"
    }
  }, [_c('p', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('quote')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.order.quote.quoteNumber) + " ")])]), _c('p', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('purchaseOrder')))]), _vm.order.quote.purchaseOrderNumber ? _c('span', [_vm._v(" " + _vm._s(_vm.order.quote.purchaseOrderNumber) + " ")]) : _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.$t('NotSpecified')) + " ")]), _c('span')]), _c('p', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('created')))]), _c('span', [_vm._v(" " + _vm._s(_vm.$d(new Date(_vm.order.quote.created), 'long')) + " ")]), _c('span')]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('invoiceID')))]), _c('span', [_vm._v(" " + _vm._s(_vm.order.invoiceNumber) + " ")]), _c('span')])] : _vm._e()], 2)], 1), _vm.order ? _c('BeelseQuote', {
    staticClass: "mt-4",
    attrs: {
      "quote": _vm.order.quote
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.order ? _c('OrderValidation', {
    attrs: {
      "order": _vm.order,
      "payment-intent-ready": !!_vm.paymentIntent,
      "private-supplier": (_vm$$appConfig$brand$ = _vm.$appConfig.brand.features.privateSuppliers.find(function (pS) {
        return _vm.order.quote.dispatch.some(function (d) {
          return pS.supplier._id === d.supplier;
        });
      })) === null || _vm$$appConfig$brand$ === void 0 ? void 0 : _vm$$appConfig$brand$.supplier
    },
    on: {
      "openPaymentModal": function ($event) {
        _vm.modalPayment = true;
      }
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }